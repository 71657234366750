import { useCallback, useContext, useEffect, useRef, useState } from "react"

import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import metadata from "metadata.json"
import { useRouter } from "next/router"
import { useTheme } from "react-jss"

import { GlobalContext } from "components/GlobalContext"
import RegistrationStatusButton from "components/ito/2022/RegistrationStatusButton"
import StickyRegister from "components/ito/2022/StickyRegister"
import CashPrizeAndReward from "components/ito/2023/landingPage/CashPrizeAndReward"
import ContactUs from "components/ito/2023/landingPage/ContactUs"
// import FAQITO2023 from "components/ito/2023/landingPage/FAQITO2023"
import FeaturedIn from "components/ito/2023/landingPage/FeaturedIn"
import ForSchool from "components/ito/2023/landingPage/ForSchool"
import HeroSection from "components/ito/2023/landingPage/HeroSection"
import ReviewButton from "components/ito/2023/landingPage/HeroSection/ReviewButton"
import TrustedSchools from "components/ito/2023/landingPage/HeroSection/TrustedSchools"
import ITOQueriesAnswered from "components/ito/2023/landingPage/ITOQueriesAnswered"
import JsonSchema from "components/ito/2023/landingPage/JsonSchema"
import NavigationSection, {
  itoPageSections,
} from "components/ito/2023/landingPage/NavigationSection"
import RatingAndReviews from "components/ito/2023/landingPage/RatingAndReviews"
import ReapRewardReport from "components/ito/2023/landingPage/ReapRewardReport"
import AboutReports from "components/ito/2023/landingPage/ReapRewardReport/AboutReports"
import GrowAndSupport from "components/ito/2023/landingPage/ReapRewardReport/GrowAndSupport"
import { itoLandingData } from "components/ito/2023/landingPage/ReapRewardReport/testmonials"
import RegistrationEndsInBanner from "components/ito/2023/landingPage/RegistrationEndsInBanner"
import SchoolLeaders from "components/ito/2023/landingPage/SchoolLeaders"
import SharingSection from "components/ito/2023/landingPage/SharingSection"
import SnapshotITO2022 from "components/ito/2023/landingPage/SnapshotITO2022"
import SyllabusAndPattern from "components/ito/2023/landingPage/SyllabusAndPattern"
import WhatIsITO from "components/ito/2023/landingPage/WhatIsITO"
import Stories from "components/ito/2023/landingPage/WhatIsITO/Stories"
import WhatIsITONumbers from "components/ito/2023/landingPage/WhatIsITO/WhatIsITONumbers"
import WhatIsITOQuestion from "components/ito/2023/landingPage/WhatIsITO/WhatIsITOQuestion"
import WhatIsITOVideo from "components/ito/2023/landingPage/WhatIsITO/WhatIsITOWithVideo"
import WhatYouGetWhatYouPay from "components/ito/2023/landingPage/WhatYouGetWhatYouPay"
import { useOlympiadPrices } from "components/registrationForm/utils/hooks/useOlympiadPrices"
import Metadata from "components/shared/metadata"
import ProgramBar from "components/shared/ProgramBar"
// import ProgramBar from "components/shared/ProgramBar"
import LoadingOverlay from "components/user/LoadingOverlay"
import { itoSections } from "utils/constants"
import { TRACKING_DATA } from "utils/googleAnalytics"
import { usePageExperiments } from "utils/hooks/usePageExperiments"

const spacing = "pb-4 sm:pb-12"

type Experiments =
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F"
  | "G"
  | "H"
  | "H2"
  | "I"
  | "J"
  | "K"
  | "L"
const ALL_EXPERIMENTS: Experiments[] = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "H2",
  "I",
  "J",
  "K",
  "L",
]

const Index = () => {
  const { currentCountry } = useContext(GlobalContext)
  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const router = useRouter()
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"))
  const { getPrice } = useOlympiadPrices({ edition: "2023" })
  const [hasVisited, setHasVisited] = useState<boolean>(false)
  const divRef = useRef<HTMLDivElement | null>(null)

  const checkDivVisiting = useCallback(() => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect()

      if (!hasVisited && rect.top <= 100) {
        setHasVisited(true)
      }
      if (hasVisited && rect.top > 100) {
        setHasVisited(false)
      }
    }
  }, [hasVisited])

  useEffect(() => {
    window.addEventListener("scroll", checkDivVisiting)
    return () => {
      window.removeEventListener("scroll", checkDivVisiting)
    }
  }, [checkDivVisiting])

  const { experiment, isLoading, TestComponent } =
    usePageExperiments<Experiments>({
      experimentKeys: ALL_EXPERIMENTS,
      defaultExperiment: "H2",
    })

  const ProgramBarEndSlot = (
    <div className="flex items-center justify-between">
      <ReviewButton className="hidden mr-2 lg:flex" hidePhoto={isLgDown} />
      <RegistrationStatusButton
        data-source={TRACKING_DATA.source.itoLandingPageHero}
        gradientButton
      />
    </div>
  )

  const AboutReportsData = itoLandingData

  const getPageContent = (variant: Experiments): JSX.Element => {
    switch (variant) {
      case "A":
        /**
         * Main (Default)
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-6 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={90}
              />
            )}
            <WhatIsITO getPrice={getPrice} spacing={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />
            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "B":
        /**
         * Main with 'Video' in 'Hero Section'
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            {/* <NoSSR>
              <RealtimeAlerts />
            </NoSSR> */}

            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
              showVideo
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-6 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}
            <WhatIsITO getPrice={getPrice} spacing={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />
            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "C":
        /**
         * Main with 'ITO Video' in 'What is ITO'
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-12 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}
            <WhatIsITOVideo spacing={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />
            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "D":
        /**
         * Case D and case A are the same for now. We have not implemented realtime registration toasts
         */
        return (
          <>
            {getPageContent("A")}
            {/* <NoSSR>
              <RealtimeAlerts />
            </NoSSR> */}
          </>
        )
      case "E":
        /**
         * Main with Syllabus Section After School Leaders Testimonials
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-12 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}
            <WhatIsITO getPrice={getPrice} spacing={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />

            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "F":
        /**
         * Main with Navigation Section after 'What is ITO'
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            {/* <NoSSR>
              <RealtimeAlerts />
            </NoSSR> */}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-12 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}
            <WhatIsITO getPrice={getPrice} spacing={spacing} />
            <NavigationSection className={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />
            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "G":
        /**
         * 'Default' with 'Sharing Section'
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-12 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}
            <WhatIsITO getPrice={getPrice} spacing={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />
            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <SharingSection className={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "H":
        /**
         * 'Default' with Different Flow (Ankit Sir's Request)
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            {/* <NoSSR>
              <RealtimeAlerts />
            </NoSSR> */}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
              // This is intentional
              variant="K"
            />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-12 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}
            <Container className="!pb-12" fullWidth={isXsDown}>
              <WhatIsITONumbers getPrice={getPrice} padding={false} />
            </Container>
            <Container className={clsx(spacing, "mb-4")}>
              <Stories />
            </Container>
            <Container className={spacing}>
              <GrowAndSupport />
            </Container>
            <RatingAndReviews spacing={spacing} />
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <Container className={spacing}>
              <WhatIsITOQuestion />
            </Container>
            <SyllabusAndPattern className={spacing} />
            <ReapRewardReport spacing={spacing} />
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <SnapshotITO2022 spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "H2":
        /**
         * 'H' with waveEffect in hero section
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            {/* <NoSSR>
              <RealtimeAlerts />
            </NoSSR> */}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
              variant="K"
              waveEffect
            />
            <ITOQueriesAnswered spacing={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
              passRef={divRef}
            />

            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-12 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                // slotEnd={ProgramBarEndSlot}
                top={0}
              />
            )}

            <Container className="!pb-12" fullWidth={isXsDown}>
              <WhatIsITONumbers getPrice={getPrice} padding={false} />
            </Container>
            <Container className={clsx(spacing, "mb-4")}>
              <Stories />
            </Container>
            <Container className={spacing}>
              <GrowAndSupport />
            </Container>
            <RatingAndReviews spacing={spacing} />
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <Container className={spacing}>
              <WhatIsITOQuestion />
            </Container>
            <SyllabusAndPattern className={spacing} />
            <ReapRewardReport spacing={spacing} />
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <SnapshotITO2022 spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "I":
        /**
         * Main with Success Stories Cards
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-6 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}
            <WhatIsITO getPrice={getPrice} spacing={spacing} isStoryCard />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />
            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "J":
        /**
         * Main with Hero section copy updates (Tags)
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
              variant="J"
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-6 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}
            <WhatIsITO getPrice={getPrice} spacing={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />
            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "K":
        /**
         * Main with Hero section copy updates (Tags & Total rewards)
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            {/* <NoSSR>
              <RealtimeAlerts />
            </NoSSR> */}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
              variant="K"
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />
            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-6 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}
            <WhatIsITO getPrice={getPrice} spacing={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />
            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )
      case "L":
        /**
         * Main with Hero section copy updates (No tags)
         */
        return (
          <>
            {isXsDown && <StickyRegister />}
            <HeroSection
              countryCode={currentCountry?.isoCode}
              getPrice={getPrice}
              variant="L"
            />
            <TrustedSchools
              className="pb-9"
              countryCode={currentCountry?.isoCode}
            />

            {!isXsDown && (
              <ProgramBar
                className="pt-0 mb-6 md:pt-3"
                curvedBar={!isSmDown}
                data={itoSections}
                fullWidth={isSmDown}
                overallTopOffset={200}
                slotEnd={ProgramBarEndSlot}
                top={76}
              />
            )}

            <WhatIsITO getPrice={getPrice} spacing={spacing} />
            <FeaturedIn
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <SyllabusAndPattern className={spacing} />
            <RatingAndReviews spacing={spacing} />
            <ReapRewardReport spacing={spacing} />
            <Container>
              <GrowAndSupport className={spacing} />
            </Container>
            <section
              className={clsx(spacing, "overflow-hidden")}
              id={itoPageSections.TESTIMONIAL}
            >
              <Container>
                <AboutReports
                  itoLandingData={AboutReportsData}
                  title="2022 Participants' Reviews on ITO Reports"
                />
              </Container>
            </section>
            <CashPrizeAndReward getPrice={getPrice} spacing={spacing} />
            <SchoolLeaders
              countryCode={currentCountry?.isoCode}
              spacing={spacing}
            />
            <WhatYouGetWhatYouPay getPrice={getPrice} spacing={spacing} />
            <SnapshotITO2022 spacing={spacing} />
            <ForSchool spacing={spacing} />
            {/* <FAQITO2023 className={spacing} getPrice={getPrice} /> */}
            <ContactUs />
          </>
        )

      default:
        return getPageContent("A")
    }
  }
  useEffect(() => {
    if (
      !isLoading &&
      router.isReady &&
      typeof window !== "undefined" &&
      router.query.scroll === "true"
    ) {
      window.scrollBy({ top: window.innerHeight, behavior: "smooth" })

      delete router.query.scroll
      router.replace(router, undefined, {
        shallow: true,
      })
    }
  }, [isLoading, router])

  return (
    <div>
      <Metadata data={metadata.ito.year2023.landing}>
        <meta content="index, follow" name="robots" />
      </Metadata>
      <JsonSchema />

      <TestComponent />

      {isLoading ? (
        <LoadingOverlay showLogo />
      ) : (
        <>
          <RegistrationEndsInBanner hasVisited={hasVisited} />
          <div className="bg-white">{getPageContent(experiment)}</div>
        </>
      )}
    </div>
  )
}

export default Index
