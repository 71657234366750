import { useEffect, useState } from "react"

import { fetchAndActivate, getValue } from "@firebase/remote-config"
import { useRouter } from "next/router"

import { remoteConfig } from "utils/firebaseInit"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { logRegistrationOnFirebase } from "utils/ito/2023/firebaseApi"
import { parseParam } from "utils/utmParams"

const TIMEOUT = 5000

type Props<T extends string> = { experimentKeys: T[]; defaultExperiment: T }

export const usePageExperiments = <T extends string>(props: Props<T>) => {
  const [experiment, setCurrentExperiment] = useState<T>(
    props.defaultExperiment
  )

  const router = useRouter()

  const [isLoading, setIsLoading] = useState(true)

  const updateParams = (value = "") => {
    const { pathname } = window.location
    const params = new URLSearchParams(window.location.search)
    if (!value) {
      params.delete("variant")
    }
    params.set("variant", value)
    router.replace(
      { pathname, query: params.toString(), hash: window.location.hash },
      undefined,
      {
        shallow: true,
      }
    )
  }

  const changeLayout = () => {
    const experiments = props.experimentKeys

    const currentExperimentIndex = experiments.indexOf(experiment)

    const newExperiment =
      currentExperimentIndex === experiments.length - 1
        ? 0
        : currentExperimentIndex + 1

    setCurrentExperiment(experiments[newExperiment])
    updateParams(experiments[newExperiment])
  }

  const TestComponent = () =>
    process.env.NODE_ENV === "development" ? (
      <>
        <button
          className="fixed p-2 text-white bg-black top-2 left-2 rounded-xl"
          style={{
            zIndex: 999,
          }}
          onClick={() => changeLayout()}
        >
          Change Variant ({experiment})
        </button>
        {/* <button
          className="fixed z-50 p-2 text-white bg-black rounded-xl right-2 top-3"
          onClick={() => {
            setRealtimeRegisteredUser({
              name: "MadMAx",
              location: "Delhi, India",
              id: (Math.floor(Math.random() * 100) + 10).toString(),
              timestamp: Date.now(),
            })
          }}
        >
          Add User
        </button> */}
      </>
    ) : null

  useEffect(() => {
    const getExperimentFromFirebase = () => {
      const race = Promise.race([
        new Promise(resolve =>
          // eslint-disable-next-line no-promise-executor-return
          setTimeout(() => {
            resolve(-1)
          }, TIMEOUT)
        ),
        new Promise(resolve => {
          if (!remoteConfig) {
            return
          }

          fetchAndActivate(remoteConfig)
            .then(res => {
              console.log({ newValueFetched: res })
              // @ts-expect-error we are checking remoteConfig above
              const val = getValue(remoteConfig, "ITO_LANDING_PAGE")
              resolve(val.asString())
            })
            .catch(console.log)
        }),
      ])
      race
        .then(val => {
          let currentExperiment = props.defaultExperiment
          if (
            typeof val === "string" &&
            props.experimentKeys.includes(val as T)
          ) {
            currentExperiment = val as T
          }

          setIsLoading(false)
          setCurrentExperiment(currentExperiment)
          updateParams(currentExperiment)

          console.log({ currentExperiment })
        })
        .catch(e => {
          console.error(e)

          const currentExperiment = props.defaultExperiment

          setIsLoading(false)
          setCurrentExperiment(currentExperiment)
          updateParams(currentExperiment)
        })
    }

    if (router.isReady) {
      const experimentFromURL = parseParam(router.query.variant)

      if (
        experimentFromURL &&
        props.experimentKeys.includes(experimentFromURL as T)
      ) {
        setIsLoading(false)
        setCurrentExperiment(experimentFromURL as T)
        updateParams(experimentFromURL)
      } else {
        getExperimentFromFirebase()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  return { experiment, isLoading, TestComponent }
}
