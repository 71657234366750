import React from "react"

import {
  Button,
  Container,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { isAfter } from "date-fns"
import Image from "next/image"
import Tarandeep from "public/assets/ito/ito-queries/tarandeep-singh.webp"

import { Calendar, Clock } from "iconoir-react"

type PointersType = {
  icon: React.ReactNode
  title: string
}
const Pointers = ({ icon, title }: PointersType) => (
  <div className="flex items-center space-x-1">
    <span className="flex items-center justify-center w-4 h-4 bg-white rounded-full">
      {icon}
    </span>
    <Typography className="!text-ito-midnight" variant="subtitle2">
      {title}
    </Typography>
  </div>
)

const pointerList: PointersType[] = [
  {
    icon: <Calendar color="#0F172A" height={16} width={16} />,
    title: "Friday, 29th Sep 2023",
  },
  {
    icon: <Clock color="#0F172A" height={16} width={16} />,
    title: "5:00 PM (IST) / 3:30 PM (GST)",
  },
]
const END_TIME = new Date("2023-09-29T13:00:00.000Z")

const ITOQueriesAnswered = ({
  className,
  spacing,
}: {
  className?: string
  spacing: string
}) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const now = new Date()

  const TarandeepAvatar = (
    <div className="flex flex-col items-center">
      <Image
        alt="tarandeep"
        className="shrink-0 w-[167px] h-[210px]"
        src={Tarandeep}
      />
      <Typography textAlign="center" variant="strong">
        Mr Tarandeep Singh
      </Typography>
      <Typography textAlign="center" variant="smallBody">
        Director of Admissions, Suraasa
      </Typography>
    </div>
  )
  const registerNow = (mobileView?: boolean) => (
    <Button
      className={clsx("!bg-[#050124]", {
        "sm:!hidden !block": mobileView,
        "!hidden sm:!block": !mobileView,
      })}
      color="black"
      component="a"
      fullWidth={mobileView}
      href="https://suraasa.co/HQbX"
      target="_blank"
      rounded
    >
      Register Now
    </Button>
  )

  if (isAfter(now, END_TIME)) {
    return null
  }

  return (
    <Container className={clsx(className, spacing)} fullWidth={isXsDown}>
      <div
        className="flex flex-col justify-between px-2 py-4 space-y-2 sm:space-y-0 sm:space-x-2 sm:px-6 sm:py-3 sm:rounded-2xl sm:flex-row bg-ito-peach-400/25"
        style={{
          backgroundImage:
            "radial-gradient(circle at 0% 50%, rgba(235, 72, 63, 0.15) 0, transparent 60%),radial-gradient(circle at 60% 50%, rgba(214, 115, 45, 0.2) 0, transparent 40%) ",
        }}
      >
        <div className="flex flex-col justify-between sm:py-3 md:py-4.5 space-y-0 sm:space-y-2">
          <div className="flex flex-col space-y-2">
            <Typography color="onSurface.800" variant="title1">
              Get Your ITO Queries Answered
            </Typography>
            <div className="flex flex-col md:flex-row space-y-0.5 sm:space-y-1 md:space-y-0 md:space-x-2">
              {pointerList.map((item, index) => (
                <Pointers {...item} key={index} />
              ))}
            </div>
          </div>
          {registerNow()}
        </div>
        <div className="flex flex-col space-y-4 sm:space-y-0 shrink-0">
          {TarandeepAvatar}
          {registerNow(true)}
        </div>
      </div>
    </Container>
  )
}

export default ITOQueriesAnswered
