import React from "react"

import { Container } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Thumbnail from "public/assets/ito/hero-section/thumbnail.webp"
import { createUseStyles } from "react-jss"

import { itoSections } from "utils/constants"

import AboutTheOrganizer from "../AboutTheOrganizer"
import HeroSectionVideo from "../HeroSection/HeroSectionVideo"
import ReportSheet from "../ReportSheet"
import SwitzerTypography from "../SwitzerTypography"

const useStyles = createUseStyles(theme => ({
  questionContainer: {
    display: "grid",
    gridTemplateColumns: "50% 45%",
    gridTemplateRows: "auto",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
    [theme.breakpoints.down("md")]: {
      gridTemplateRows: "auto auto",
      gridTemplateColumns: "auto",
      justifyItems: "center",
    },
  },
}))
const WhatIsITOVideo = ({ spacing }: { spacing: string }) => {
  const classes = useStyles()
  return (
    <Container className={spacing}>
      <section
        className={clsx(classes.questionContainer)}
        id={itoSections.ABOUT}
      >
        <div>
          <SwitzerTypography
            className="mb-4 !text-ito-midnight"
            fontFamily="Switzer Bold"
            variant="title1"
          >
            What is International Teachers’ Olympiad?
          </SwitzerTypography>
          <SwitzerTypography
            className="!text-ito-midnight mb-3"
            fontFamily="Switzer Regular"
            variant="body"
          >
            The International Teachers' Olympiad (ITO) is an opportunity for
            teachers to assess their pedagogical skills.
            <br />
            <br />
            During the Olympiad, you undergo a one-hour online assessment. Based
            on your performance, you receive a{" "}
            <ReportSheet
              render={() => (
                <a
                  className="hover:underline"
                  href="https://assets.suraasa.com/ito/2023/sample-teacher-report.pdf"
                  rel="noreferrer"
                  style={{ font: "inherit", color: "#3B82F6" }}
                  target="_blank"
                >
                  pedagogy report
                </a>
              )}
            />{" "}
            to track your progress year-on-year & growth plans to become even
            better educators.
            <br />
            <br />
            You can attempt the Olympiad without the fear of being judged. Your
            report will be 100% confidential & only accessible to you.
          </SwitzerTypography>
        </div>
        <HeroSectionVideo poster={Thumbnail.src} />
      </section>
      <AboutTheOrganizer />
    </Container>
  )
}

export default WhatIsITOVideo
