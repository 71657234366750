import { PropsWithChildren } from "react"

import { CircularProgress } from "@suraasa/placebo-ui"
import Image from "next/image"
import { createUseStyles } from "react-jss"

import { useScrollShiftOffset } from "utils/hooks/useScrollShiftOffset"

const useStyles = createUseStyles(theme => ({
  progressOverlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    top: 0,
    zIndex: 51,
    background: "rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(0.5),
  },
}))

type Props = {
  /**
   * if true renders `Suraasa Butterfly` instead of `CircularProgress`
   */
  showLogo?: boolean
}

const LoadingOverlay: React.FC<PropsWithChildren<Props>> = ({
  children,
  showLogo = false,
}) => {
  const classes = useStyles()

  useScrollShiftOffset(true, { ignorePadding: true })

  return (
    <div className={classes.progressOverlay}>
      {showLogo ? (
        <Image
          alt="loading"
          className="h-9 w-9"
          height={72}
          src="/assets/logos/suraasa-colored-small.svg"
          width={72}
        />
      ) : (
        <CircularProgress />
      )}
      {children}
    </div>
  )
}

export default LoadingOverlay
