import React from "react"

import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image, { StaticImageData } from "next/image"
import { createUseStyles } from "react-jss"

import SwitzerTypography from "../SwitzerTypography"

const useStyles = createUseStyles(() => ({
  price: {
    fontFamily: "Switzer Bold",
    fontSize: 32,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.64px",
  },
}))
type Props = {
  data: { title: string; price: string; img: StaticImageData }
  className?: string
  imgClassName?: string
}

const Cards = ({ className, data, imgClassName }: Props) => {
  const classes = useStyles()
  const { title, price, img } = data
  return (
    <div
      className={clsx(
        "rounded-2xl relative text-ito-midnight px-3 pt-2 pb-3 bg-critical-100 overflow-hidden",
        className
      )}
    >
      <SwitzerTypography
        className="mb-8 sm:w-[334px]"
        fontFamily="Switzer Regular"
        variant="subtitle1"
      >
        {title}
      </SwitzerTypography>
      <div className="p-2 bg-white rounded-2xl shadow-[0px_6px_15px_0px_rgba(0,0,0,0.05)] w-fit relative z-[1]">
        <Typography variant="preTitle">Worth</Typography>
        <span className={classes.price}>{price}</span>
      </div>
      <Image alt="img" className={imgClassName} src={img} />
    </div>
  )
}

export default Cards
