import React from "react"

import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import Certificate from "public/assets/ito/what-you-get-what-you-pay/certificate.webp"
import Circle from "public/assets/ito/what-you-get-what-you-pay/circle.svg"
import Community from "public/assets/ito/what-you-get-what-you-pay/community.webp"
import CommunityMobile from "public/assets/ito/what-you-get-what-you-pay/community-mobile.webp"
import Masterclass from "public/assets/ito/what-you-get-what-you-pay/masterclass.webp"
import Report from "public/assets/ito/what-you-get-what-you-pay/report.webp"
import Reports from "public/assets/ito/what-you-get-what-you-pay/reports.webp"
import { createUseStyles, useTheme } from "react-jss"

import TextDivider from "components/registrationForm/TextDivider"
import { PriceCategories } from "utils/ito/utils"

import { itoPageSections } from "../NavigationSection"
import SwitzerTypography from "../SwitzerTypography"

import Cards from "./Cards"

const useStyles = createUseStyles(theme => ({
  vsContainer: {
    border: "1px solid rgba(226, 232, 240, 0.50)",
    background:
      "linear-gradient(90deg, rgba(84,230,144,0.25) 0%, rgba(255,255,255,0.75) 45%, rgba(255,255,255,0.5) 55%, rgba(136,175,247,0.25) 100%)",
  },
  vsSpan: {
    fontFamily: "Inter",
    fontSize: 24,
    fontStyle: "italic",
    fontWeight: 600,
    lineHeight: "130%",
    letterSpacing: "-0.48px",
  },
  barData: {
    fontFamily: "Switzer Bold",
    fontSize: 32,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.64px",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "auto auto",
    gap: "24px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  masterclass: {
    background:
      "radial-gradient(circle, rgba(116,63,245,0.15) 0%, rgba(234,89,202,0.15) 50%, rgba(116,63,245,0.15) 100%)",
  },
  certificate: {
    background:
      "linear-gradient(90deg, rgba(105,219,123,0.15) 30%, rgba(52,109,242,0.15) 70%, rgba(82,149,252,0.15) 100%)",
  },
  global: {
    background:
      "radial-gradient(circle, rgba(235,150,77,0.25) 0%, rgba(235,72,63,0.15) 50%, rgba(235,150,77,0.25) 100%)",
  },
  report: {
    background:
      "linear-gradient(60deg, rgba(52,109,242,0.25) 0%, rgba(172,131,255,0.25) 90%, rgba(255,255,255,0.5) 100%)",
  },
  community: {
    background:
      "linear-gradient(100deg, rgba(84,230,144,0.1) 0%, rgba(82,149,252,0.25) 15%, rgba(172,131,255,0.25) 40%, rgba(234,89,202,0.25) 61%, rgba(254,150,227,0.25) 78%, rgba(243,179,231,0.25) 100%)",
  },
}))
const WhatYouGetWhatYouPay = ({
  getPrice,
  spacing,
}: {
  getPrice: (key: PriceCategories<"2023">) => string
  spacing: string
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Container className={spacing}>
      <div id={itoPageSections.WHAT_YOU_GET_WHAT_YOU_PAY}>
        <SwitzerTypography
          className="text-center  !text-ito-midnight mb-3"
          fontFamily="Switzer Bold"
          variant={isXsDown ? "title2" : "title1"}
        >
          {" "}
          What You Get is Much More Than What You Pay <br />{" "}
          <span className="relative" style={{ font: "inherit" }}>
            5000%,{" "}
            <div className="absolute -left-2.5 -top-1.25">
              <Image alt="circle" src={Circle} />
            </div>
          </span>{" "}
          to be exact!
        </SwitzerTypography>
        <SwitzerTypography
          className="mb-6 max-w-[540px] w-full mx-auto sm:!text-center !text-start !block"
          fontFamily="Switzer Regular"
        >
          You are already a winner for us if you're a teacher. Just attempting
          the Olympiad gives you access to so many benefits!
        </SwitzerTypography>
        <div
          className={clsx(
            classes.vsContainer,
            "flex flex-col items-center justify-between gap-2 p-4 mb-6 sm:flex-row rounded-3xl text-ito-midnight"
          )}
        >
          <div className="text-center sm:text-start">
            <SwitzerTypography fontFamily="Switzer Regular" variant="largeBody">
              Huge Rewards Worth
            </SwitzerTypography>
            <span className={classes.barData}>{getPrice("totalRewards")}</span>
          </div>

          <div className="max-w-none w-full sm:max-w-[211px] shrink-0 grow ">
            <TextDivider>
              <span className={classes.vsSpan}>vs</span>
            </TextDivider>
          </div>

          <div className="text-center sm:text-end">
            <SwitzerTypography fontFamily="Switzer Regular" variant="largeBody">
              All You Need to Pay
            </SwitzerTypography>
            <span className={classes.barData}>
              {getPrice("registrationFee")}
            </span>
          </div>
        </div>
        <div className={clsx(classes.container, "mb-3")}>
          <Cards
            className={classes.report}
            data={{
              title: "Your Skill Assessment and Analysis",
              price: getPrice("skillAnalysisReport"),
              img: Report,
            }}
            imgClassName="absolute bottom-[-65%] -rotate-12 rounded-xl right-0 sm:max-w-[220px] max-w-[210px] w-full"
          />
          <Cards
            className={classes.masterclass}
            data={{
              title: "Reports & Growth Plans for 6 Pedagogy Areas",
              price: getPrice("growthPlan"),
              img: Reports,
            }}
            imgClassName="absolute sm:max-w-[295px]  max-w-[300px] w-full -rotate-6 sm:-right-6 -right-9 -bottom-11"
          />
          <Cards
            className={classes.certificate}
            data={{
              title: "Free Access to 30 Hours of Masterclasses",
              price: getPrice("masterclasses"),
              img: Masterclass,
            }}
            imgClassName="absolute bottom-0 right-0 sm:max-w-[260px] max-w-[210px] w-full"
          />
          <Cards
            className={classes.global}
            data={{
              title: "International Certificate of Participation",
              price: getPrice("certificate"),
              img: Certificate,
            }}
            imgClassName="absolute bottom-0 right-0 sm:max-w-[260px]  max-w-[210px] w-full"
          />
        </div>
        <Cards
          className={classes.community}
          data={{
            title: "Access to Exclusive Global Teacher Community",
            price: "Priceless",
            img: isXsDown ? CommunityMobile : Community,
          }}
          imgClassName="absolute sm:bottom-0 -bottom-7 md:right-6 sm:right-2 right-0 sm:max-w-[300px] max-w-[200px] w-full"
        />
      </div>
    </Container>
  )
}

export default WhatYouGetWhatYouPay
