import React from "react"

import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import TinyInvestment from "public/assets/ito/what-is-ito/tiny-investment.webp"
import TinyInvestmentTab from "public/assets/ito/what-is-ito/tiny-investment-tab.webp"
import { createUseStyles, useTheme } from "react-jss"

import useDetectCountry from "utils/hooks/useDetectCountry"
import { PriceCategories } from "utils/ito/utils"

import SwitzerTypography from "../SwitzerTypography"

// import Stories from "./Stories"

const useStyles = createUseStyles(theme => ({
  title: {
    fontFamily: "Switzer Bold",
    fontSize: 32,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.64px",
  },
  questionContainer: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto",
    justifyItems: "end",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      gridTemplateRows: "auto auto",
      gridTemplateColumns: "auto",
      justifyItems: "center",
    },
  },
  gradientBackground: {
    background:
      "linear-gradient(160deg, rgba(255,114,34, 0.3) 0%, rgba(255,255,255,0.5) 48%, rgba(255,255,255,0.5) 53%, rgba(255,197,54, 0.3) 100%)",
  },
  textBackground: { background: "#FDFF9E" },
}))

const Card = ({ title, subTitle }: { title: string; subTitle: string }) => {
  const classes = useStyles()
  return (
    <div className="w-[207px] h-8.5 flex flex-col gap-0.5 items-center justify-center text-center">
      <span className={clsx(classes.title, "text-ito-midnight")}>{title}</span>
      <SwitzerTypography
        className="!text-ito-midnight"
        fontFamily="Switzer Regular"
        variant="largeBody"
      >
        {subTitle}
      </SwitzerTypography>
    </div>
  )
}

const WhatIsITONumbers = ({
  getPrice,
  padding = true,
}: {
  getPrice: (key: PriceCategories<"2023">) => string
  padding?: boolean
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const country = useDetectCountry()

  const formatNumber = () => {
    if (country?.isoCode === "IN") {
      return "3,00,000"
    }
    return "300,000"
  }

  return (
    <div
      className={clsx({
        "pb-12": padding,
      })}
    >
      <div
        className={clsx(
          classes.gradientBackground,
          "flex flex-col items-center justify-between gap-3 px-4 py-3 sm:items-stretch sm:gap-0 sm:flex-row sm:rounded-3xl rounded-0 bg-critical-500 relative"
        )}
      >
        <Card subTitle="Teachers Expected" title={formatNumber()} />
        <div className="sm:w-[1px] sm:h-auto h-[1px] w-full rounded-full bg-onSurface-300" />
        <Card
          subTitle="Total Cash Prizes upto"
          title={getPrice("totalPrizePool")}
        />
        <div className="sm:w-[1px] sm:h-auto h-[1px] w-full rounded-full bg-onSurface-300" />
        <Card subTitle="Registration Fee" title={getPrice("registrationFee")} />

        <Image
          alt="tiny-investment"
          className="absolute md:max-w-[150px] sm:max-w-[191px] max-w-[160px] w-full md:right-0 md:-bottom-7.5 sm:-bottom-10 sm:right-0 -bottom-8"
          src={isSmDown ? TinyInvestmentTab : TinyInvestment}
        />
      </div>
    </div>
  )
}

export default WhatIsITONumbers
