import { Container } from "@suraasa/placebo-ui"
import Assess from "public/assets/ito/reward-report/assess.webp"
import Focus from "public/assets/ito/reward-report/focus.webp"
import Goals from "public/assets/ito/reward-report/goals.webp"

import { itoSections } from "utils/constants"

import GradientButton from "../GradientButton"
import ReportSheet from "../ReportSheet"
import SwitzerTypography from "../SwitzerTypography"

import Cards from "./Cards"

const ReapRewardReport = ({ spacing }: { spacing: string }) => (
  <section className={spacing} id={itoSections.REPORT}>
    <Container>
      <SwitzerTypography
        className="!text-ito-midnight block sm:text-center text-start mb-6"
        fontFamily="Switzer Bold"
        variant="title1"
      >
        Your Olympiad Report Card
      </SwitzerTypography>
      <div className="grid grid-cols-12 gap-2 mb-4">
        <Cards
          cardName="assess"
          className="col-span-12 md:col-span-4 sm:col-span-6"
          src={Assess}
          title="Assess where you stand globally"
        />
        <Cards
          cardName="focus"
          className="col-span-12 md:col-span-4 sm:col-span-6"
          src={Focus}
          title="Understand focus areas for growth"
        />
        <Cards
          cardName="goals"
          className="col-span-12 md:col-span-4 sm:col-span-6 sm:col-start-4 md:col-start-9"
          src={Goals}
          title="Get actionables to achieve your goals"
        />
      </div>
      <ReportSheet
        render={() => (
          <GradientButton
            className="mx-auto !block"
            component="a"
            href="https://assets.suraasa.com/ito/2023/sample-teacher-report.pdf"
            rel="noreferrer"
            size="lg"
            target="_blank"
          >
            View Sample Report
          </GradientButton>
        )}
      />
    </Container>
  </section>
)

export default ReapRewardReport
