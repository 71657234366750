import React from "react"

import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui"
import { useTheme } from "react-jss"

import { PriceCategories } from "utils/ito/utils"

import Stories from "./Stories"
import WhatIsITONumbers from "./WhatIsITONumbers"
import WhatIsITOQuestion from "./WhatIsITOQuestion"

const WhatIsITO = ({
  getPrice,
  spacing,
  isStoryCard,
}: {
  getPrice: (key: PriceCategories<"2023">) => string
  spacing: string
  isStoryCard?: boolean
}) => {
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <div className={spacing}>
      <Container fullWidth={isXsDown}>
        <WhatIsITONumbers getPrice={getPrice} />
        <WhatIsITOQuestion />
        <Stories isStoryCard={isStoryCard} />
      </Container>
    </div>
  )
}

export default WhatIsITO
