import React, { useState } from "react"

import {
  Button,
  Dialog,
  IconButton,
  theme,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { createUseStyles } from "react-jss"

import { Mail, Xmark } from "iconoir-react"

import { ToggleValue } from "utils/hooks/useToggle"

const duration = 225
const useStyles = createUseStyles({
  "@keyframes slideXs": {
    "0%": {
      bottom: "-100%",
    },
    "100%": {
      bottom: theme.spacing(0),
    },
  },
  "@keyframes slideSm": {
    "0%": {
      bottom: "-100%",
    },
    "100%": {
      bottom: theme.spacing(3),
    },
  },
  "@keyframes slideLg": {
    "0%": {
      bottom: "-100%",
    },
    "100%": {
      bottom: theme.spacing(4),
    },
  },

  shareButton: {
    background: "#fff",
    color: theme.colors.primary[500],
    "&:hover": {
      background: "rgba(255, 255, 255, 204)",
      color: theme.colors.primary[500],
    },
  },

  shareDialog: {
    padding: theme.spacing(4, 2.5),
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    width: "100% !important",
    background: "#fff",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: theme.spacing(0),
    marginLeft: "auto",
    marginRight: "auto",
    animation: `$slideXs ${duration}ms`,
    [theme.breakpoints.up("sm")]: {
      borderRadius: theme.spacing(1),
      width: "calc(100% - 80px) !important",
      bottom: theme.spacing(3),
      animation: `$slideSm ${duration}ms`,
      padding: theme.spacing(4, 6),
    },

    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 160px) !important",
    },

    [theme.breakpoints.up("lg")]: {
      width: "calc(100% - 448px) !important",
      maxWidth: "800px",
      animation: `$slideLg ${duration}ms`,
      bottom: theme.spacing(4),
    },
  },

  cancelButton: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },

  copyToClipboard: {
    background: "#fff",
  },

  socialIcon: {
    display: "flex",
    borderRadius: "50%",
    border: `1px solid ${theme.colors.onSurface[300]}`,
    marginRight: theme.spacing(2),
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      height: theme.spacing(8),
      width: theme.spacing(8),
      marginRight: theme.spacing(4),
    },
  },

  width100: {
    width: "100%",
  },
  copyLink: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    border: `1px solid ${theme.colors.onSurface[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    overflowY: "auto",
    maxHeight: "50vh",
    "& p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "pre",
    },
  },
})

export function popupWindow(
  windowUrl: string,
  w = 600,
  h = 500,
  windowName = "Suraasa"
) {
  if (!window.top) {
    return
  }
  const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
  const x = window.top.outerWidth / 2 + window.top.screenX - w / 1.5
  return window.open(
    windowUrl,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  )
}

type Platforms = "mail" | "whatsapp" | "telegram" | "linkedin" | "facebook"
type Props<T extends Platforms> = {
  open: boolean
  toggle: ToggleValue
  data: {
    url: string
    text?: string
    email?: { subject: string }
  }
  platforms: T[]
}

function ShareCard<T extends Platforms>({
  toggle,
  data: { url, text, email },
  platforms,
}: Omit<Props<T>, "open">): JSX.Element {
  const classes = useStyles()

  const encodedText = encodeURIComponent(text || url)

  const [copied, setCopied] = useState(false)

  const mailText = encodedText.replaceAll("\n", "%0D%0A")

  const LINKEDIN_URL = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`

  const FACEBOOK_URL = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${encodedText}`

  const WHATSAPP_URL = `https://api.whatsapp.com/send?text=${encodedText}`

  const TELEGRAM_URL = `https://telegram.me/share/url?url=${encodedText}`

  const EMAIL_URL = `mailto:?subject=${email?.subject}&body=${mailText}`

  function copyToClipboard() {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  const platformDetails: Record<
    Platforms,
    {
      displayText: string
      url: string
      imgSource: string
    }
  > = {
    whatsapp: {
      displayText: "WhatsApp",
      url: WHATSAPP_URL,
      imgSource: "/assets/icons/whatsapp-icon.svg",
    },
    telegram: {
      displayText: "Telegram",
      url: TELEGRAM_URL,
      imgSource: "/assets/icons/telegram-icon.svg",
    },
    linkedin: {
      displayText: "LinkedIn",
      url: LINKEDIN_URL,
      imgSource: "/assets/icons/linkedin-icon.svg",
    },
    facebook: {
      displayText: "Facebook",
      url: FACEBOOK_URL,
      imgSource: "/assets/icons/facebook-icon.svg",
    },
    mail: {
      displayText: "Mail",
      url: EMAIL_URL,
      imgSource: "",
    },
  }

  const getPlatformIcon = (platform: Platforms) => (
    <Tooltip
      color="light"
      direction="bottom"
      title={platformDetails[platform].displayText}
    >
      <IconButton
        className={classes.socialIcon}
        onClick={() => popupWindow(platformDetails[platform].url, 650, 650)}
      >
        {platform === "mail" ? (
          <Mail height={36} width={36} />
        ) : (
          /* eslint-disable-next-line @next/next/no-img-element */
          <img alt={platform} src={platformDetails[platform].imgSource} />
        )}
      </IconButton>
    </Tooltip>
  )

  return (
    <div>
      <div
        className={clsx(
          "flex items-center justify-between mb-2 sm:mb-4",
          classes.width100
        )}
      >
        <Typography display="block" variant="title1">
          Share via
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.cancelButton}
          color="black"
          size="sm"
          onClick={() => toggle()}
        >
          <Xmark height="24px" width="24px" />
        </IconButton>
      </div>

      <div className="flex mb-3 sm:mb-6">
        {platforms.map(item => getPlatformIcon(item))}
      </div>

      <Typography className="mb-1" variant="subtitle2">
        or copy & share
      </Typography>
      <div className={classes.copyLink}>
        <Typography style={{ whiteSpace: "pre-wrap" }} variant="body">
          {text || url}
        </Typography>
        <CopyToClipboard text={text || url} onCopy={() => copyToClipboard()}>
          <Button className={classes.copyToClipboard} variant="text">
            {copied ? "Copied!" : "Copy"}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  )
}

function SocialShareDialog<T extends Platforms>({
  open,
  toggle,
  data,
  platforms = ["whatsapp", "facebook", "linkedin", "mail", "telegram"],
}: Omit<Props<T>, "platforms"> & { platforms?: Platforms[] }): JSX.Element {
  const classes = useStyles()

  if (platforms.includes("mail") && data.email?.subject == null) {
    throw new Error("Please provide email subject")
  }

  return (
    <Dialog
      animation="none"
      className={classes.shareDialog}
      open={open}
      onRequestClose={() => toggle()}
    >
      <ShareCard data={data} platforms={platforms} toggle={toggle} />
    </Dialog>
  )
}

export default SocialShareDialog
