import React, { useEffect, useRef, useState } from "react"

import { Button, Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import RegistrationStatusButton from "components/ito/2022/RegistrationStatusButton"
import Countdown from "components/ito/2023/landingPage/RegistrationEndsInBanner/Countdown"
import { useRegistrationCloseTimer } from "components/registrationForm/utils/hooks/useRegistrationCloseTimer"
import { TRACKING_DATA } from "utils/googleAnalytics"

const useStyles = createUseStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  selected: {
    borderBottom: `2px solid ${theme.colors.primary[500]}`,
    paddingBottom: "8px",
  },

  fixedBar: {
    position: "sticky",
    zIndex: 6,
  },
  textContainer: {
    width: "50%",
    maxWidth: "495px",
  },
}))

type Props = {
  data: Record<string, string>
  slotEnd?: React.ReactNode
  top?: number
  curvedBar?: boolean
  className?: string
  fullWidth?: boolean
  overallTopOffset?: number
}
function ProgramBar({
  data,
  overallTopOffset = 0,
  top,
  slotEnd,
  curvedBar = false,
  className,
  fullWidth = false,
}: Props) {
  const classes = useStyles()
  const sectionsIdList = Object.values(data)
  const { hours, days, minutes, seconds } = useRegistrationCloseTimer()
  const [selected, setSelected] = useState(sectionsIdList[0])

  const sections = useRef<NodeListOf<HTMLElement>>()

  const handleScroll = () => {
    const pageYOffset = window.scrollY + overallTopOffset

    let newActiveSection = null

    if (sections.current)
      sections.current.forEach((section, i) => {
        if (
          pageYOffset >= section.offsetTop &&
          pageYOffset < section.offsetTop + section.offsetHeight
        ) {
          newActiveSection = sectionsIdList[i]
        }
      })

    if (newActiveSection) setSelected(newActiveSection)
  }

  useEffect(() => {
    const idsAsString = sectionsIdList.map(id => `#${id}`).join(", ")

    sections.current = document.querySelectorAll(idsAsString)

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={clsx(
        { [classes.fixedBar]: top !== undefined, "bg-white": !curvedBar },
        classes.root,
        className
      )}
      style={{ top }}
    >
      <Container fullWidth={fullWidth}>
        <div
          className={clsx(
            "flex items-center justify-between py-0.75 border-y border-onSurface-100",
            {
              "px-2 py-1.5 rounded-full border bg-white": curvedBar,
            }
          )}
          style={{
            boxShadow: curvedBar
              ? "0px 6px 15px 0px rgba(0, 0, 0, 0.05)"
              : undefined,
          }}
        >
          <div className="flex item-center gap-2 py-1.5">
            {sectionsIdList.map(item => (
              <div key={item}>
                <Button
                  component="a"
                  href={`#${item}`}
                  variant="text"
                  // onClick={() => setSelected(item)}
                >
                  {curvedBar ? (
                    <Typography
                      color={
                        selected === item ? "primary.500" : "onSurface.700"
                      }
                      variant={selected === item ? "strong" : "body"}
                    >
                      {item}
                    </Typography>
                  ) : (
                    <Typography
                      className={clsx({
                        [classes.selected]: selected === item,
                      })}
                      color={
                        selected === item ? "primary.500" : "onSurface.700"
                      }
                      variant="body"
                    >
                      {item}
                    </Typography>
                  )}
                </Button>
              </div>
            ))}
          </div>
          <div className="px-1">
            {slotEnd || (
              <div className="flex items-center justify-between">
                <Countdown
                  className="mr-2"
                  days={days}
                  hours={hours}
                  minutes={minutes}
                  seconds={seconds}
                  isMini
                />
                <RegistrationStatusButton
                  data-source={TRACKING_DATA.source.itoLandingPageHero}
                  gradientButton
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ProgramBar
