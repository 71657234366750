import React from "react"

import { Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"

import { useRegistrationCloseTimer } from "components/registrationForm/utils/hooks/useRegistrationCloseTimer"

import Countdown from "./Countdown"

const RegistrationEndsInBanner = ({ hasVisited }: { hasVisited: boolean }) => {
  const { hours, days, minutes, seconds, show } = useRegistrationCloseTimer()

  if (!show) return null

  return (
    <div
      className={clsx(
        "text-white bg-[#121212] hidden sm:block sticky top-0 z-navbar transition-visibility duration-300",
        {
          "!hidden": hasVisited,
        }
      )}
    >
      <Container>
        <div className="flex gap-2 items-center justify-between min-h-[89px]">
          <Typography variant="title3">
            Hurry! Registrations Ending in
          </Typography>
          <Countdown
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
        </div>
      </Container>
    </div>
  )
}

export default RegistrationEndsInBanner
